// 
// type.scss
//


.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 10px 0;
    color: $gray-800;
    font-weight: 600;
}

p {
    line-height: 1.6;
}

// Font sizes (Custom)
.fs-11 {
    font-size: 11px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-17 {
    font-size: 17px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-19 {
    font-size: 19px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.fs-24 {
    font-size: 24px !important;
}

// text utilies
.text-unline-dashed {
    border-bottom: $gray-400 1px dashed !important;
    padding-bottom: 1px;
}

.line-height-normal {
    line-height: 1;
}

// Font Family
// .font-family-secondary {
//     font-family: $font-family-secondary;
// }

.fw-medium {
    font-weight: $font-weight-medium;
}

.fw-semibold {
    font-weight: $font-weight-semibold;
}

.highlight {
    position: relative;
    z-index: 1;
    width: fit-content;

    &::before {
        content: '';
        display: block;
        position: absolute;
        background: $gray-100;
        width: 100%;
        height: 1.2rem;
        z-index: -1;
        right: -.5rem;
        bottom: .35rem;
    }

    @each $color,
    $value in $theme-colors {
        &.highlight-#{$color} {
            &::before {
                background: lighten($value, 25%) !important
            }
        }
    }
}