.how_xuno {
    background-color: #F2F5FF;

    li {
        position: relative;
        background-color:  #fff;
        padding: 18px 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: baseline;
        &:nth-last-child(1) {
            &::after {
                display: none;
            }
        }

        h2 {
            font-size: 3rem;
            font-weight: 400;
            color: #0055FF;
            margin-top: 0;
            line-height: 1;
        }
    }

    &.brands {
        li {
            &::after {
                right: -24%;
                @media (min-width: 1920px) {
                    right: -15%;
                }
            }
        }

        .d-grid {
            grid-template-columns: repeat(3, 1fr);
            gap: 2rem;

            @media (max-width: 1200px) {
                grid-template-columns: repeat(2, 1fr);
                gap: 2rem;
            }

            @media (max-width: 767px) {
                grid-template-columns: repeat(1, 1fr);
                gap: 2rem;
            }
        }
    }

    .d-grid {
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;

        @media (max-width: 1200px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 2rem;
        }

        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 2rem;
        }
    }
}

.attention_xuno {
    .attention_xuno_card {
        background-color: #F2F5FF;
        border-radius: 12px;

        @media (max-width: 767px) {
            margin-bottom: 1.5rem;
        }

        img {
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
        }
    }
}